import '../sass/font.css';
import Vue from 'vue';
import VModal from 'vue-js-modal/dist/index.nocss.js';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '../sass/tailwind-light.css';
import Notifications from 'vue-notification';
import * as Sentry from '@sentry/vue';
import isUndefined from 'lodash-es/isUndefined';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import hidesObjectNumber from '@/mixins/hidesObjectNumber';
import booleanIcon from '@/mixins/booleanIcon';
import btn from 'uiv/dist/Btn';
import Axios from './axios';
import './icons';
import './vee-validate';
import Breadcrumb from './components/Breadcrumb.vue';
import toLocalizedNumber from './filters/toLocalizedNumber';
import '../sass/app.scss';
import 'vue-js-modal/dist/styles.css';
import {createPinia, PiniaVuePlugin} from "pinia";
import {createORM} from "pinia-orm";
import VueRouter from "vue-router";
import useDataAccessGuard from "@/middleware/useDataAccessGuard.js";
import routes from './routes'
import App from "@/App.vue";
import {useAuthenticationGuard} from "@/middleware/useAuthenticationGuard.js";

const pinia = createPinia().use(createORM())
Vue.use(PiniaVuePlugin)
Vue.use(VueRouter);
Vue.use(PrimeVue, {
    locale: {
        firstDayOfWeek: 1,
        dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
        monthNames: [ "Jänner","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember" ],
        monthNamesShort: [ "Jän", "Feb", "Mär", "Apr", "Mai", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
        today: 'Heute',
        clear: 'Löschen',
        dateFormat: 'dd.mm.yy',
        weekHeader: 'KW'
    }
});

const router = new VueRouter(routes);

// Guards
router.beforeEach(useAuthenticationGuard(router));
router.beforeEach(useDataAccessGuard(router));

Vue.config.productionTip = false;

Vue.prototype.$appVersion = '3.8.4';
Vue.prototype.$appVersionSuffix = import.meta.env.VITE_VERSION_SUFFIX;

Vue.use(VModal, { dialog: true });
Vue.use(Notifications, { duration: 8000 });

Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  environment: import.meta.env.VITE_ENVIRONMENT,
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
  release: `vdm@${Vue.prototype.$appVersion}`,
    integrations: [Sentry.httpClientIntegration()],
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Avoided redundant navigation to current location', 'Redirected when going from ', 'Navigation cancelled from', 'Navigation aborted from', 'Request aborted'],
});

Vue.mixin(hidesObjectNumber);
Vue.mixin(booleanIcon);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('Btn', btn);

Vue.mixin({
  methods: {
    getRouteNameWithProperPrefix(objectType, routeName) {
      if (objectType === 'flats') {
        return `tenant.flat.${routeName}`;
      }
      return `property-manager.property.${routeName}`;
    },
  },
});

Vue.filter('toLocalizedNumber', toLocalizedNumber);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessages = {
      network: {
        type: 'error',
        text: 'Netzwerkfehler. Bitte prüfen Sie Ihre Internetverbindung.',
          duration: 10000
      },
      server: {
        type: 'error',
        text: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        duration: 10000
      },
      auth: {
        type: 'warn',
        text: 'Sie wurden aus Sicherheitsgründen abgemeldet.',
          duration: 10000
      }
    };

    if (isUndefined(error.response)) {
      Vue.notify(errorMessages.network);
    } else if ([500, 429].includes(error.response.status)) {
        let message = errorMessages.server;
        if (error.response.data && error.response.data.length > 0) {
            message = {
                type: 'error',
                text: error.response.data,
                duration: 10000
            };
        }
        Vue.notify(message);
    } else if ([401, 403].includes(error.response.status)) {
      Vue.notify(errorMessages.auth);
      router.push({ name: 'home' });
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = Axios;

new Vue({
    router,
    render: (h) => h(App),
    pinia
}).$mount('#app');
